import React from 'react'
import headerImage from '../images/nav_logo.png'


export default function Header() {

  return (
    <div className='nav'>
      <img src={headerImage} alt="fu" />
    </div>
  )

}