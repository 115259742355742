import React from 'react'
import headerImage from '../images/home_header_wide.png'


export default function Header() {

  return (
    <div className='homeheader'>
      <img src={headerImage} alt="fu" />
    </div>
  )


}